<template>
  <div>
    <a-page-header title="Настройка прав пользователей" />

    <div class="card">
      <div class="card-header">
        <a-button class="mr-2" @click="save" :loading="saving">
          <template #icon><i class="fa fa-save" /></template>
        </a-button>
        <a-button class="mr-2" @click="reset">
          <template #icon><i class="fa fa-undo" /></template>
        </a-button>
      </div>
      <div class="card-body">
        <a-table 
          :columns="columns" 
          :data-source="rows"
          :loading="loading"
          :pagination="false"
        >
          <template #checkbox="{ record, column }">
            <span>
              <a-switch v-model:checked="record[column.dataIndex]" />
            </span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { showConfirm } from "@/helpers/modals.js";
import { success, error } from "@/helpers/notifications.js";

const baseUrl = "UserRoleManage";
const getCol = (title, name) => ({ title: title, dataIndex: name, key: name, slots: {customRender: 'checkbox'} });

export default {
  name: "UserRoleManager",
  data() {
    return {
      saving: false,
      loading: false,
      data: null,
      columns: [
        { title: "Роль", dataIndex: "title", key: "title", fixed: 'left' },
        getCol("Изменение статуса технической экспертизы", "techExpEdit"),
      ],
      rows: [],
    }
  },
  methods: {
    log(obj) { console.log(obj) },
    async load() {
      this.loading = true;
      await this.$nextTick();
      try {
        let data = (await axios.get(baseUrl)).data;
        this.rows = data.map(row => {
          row.key = row.id;
          return row;
        });
      }
      catch(e) {
        error("Ошибка при загрузке данных");
        console.error(e);
      }
      finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      await this.$nextTick();
      try {
        await axios.post(baseUrl, this.rows);
        success("Сохранено");
      }
      catch(e) {
        error("Ошибка при сохранении");
        console.error(e);
      }
      finally {
        this.loading = false;
      }
    },
    reset() {
      showConfirm({ ok: this.load });
    }
  },
  created() {
    this.load();
  }
}
</script>

<style>

</style>